body {
  background-color: rgb(234, 234, 234) !important;
}

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(135deg,
      rgb(223, 106, 67) 0%,
      rgb(255, 138, 99) 69%,
      rgb(255, 170, 119) 89%) !important;
}

@media only screen and (min-width: 1200px) {
  .ui.container {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(135deg,
      rgb(24, 42, 115) 0%,
      rgb(33, 138, 174) 69%,
      rgb(32, 167, 172) 89%) !important;
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

/*end home page styles*/

/*DatePicker Styles*/

.DatePicker {
  width: 16em;
}

/*end DatePicker Styles*/

/*Web/Curso Dropdowns Styles*/

.MultiselectDropdowns {
  width: 100%;
}

/*end DatePicker Styles*/

.metricsGrid {
  box-shadow: 0 1px 20px #ccc;
  background: #fff;
  border-radius: 10px;
}

.ui.fixed.menu+.ui.grid {
  padding-top: 0 !important;
}

/*Custom styles*/

.action-button {
  background-color: #0099b2 !important;
  color: #fff !important;
}

.ui.table thead th {
  background: rgb(255, 170, 119, .6) !important;
}

.iconButton {
  cursor: pointer;
}

.iconButton:hover {
  color: #0099b2;
}